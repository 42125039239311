import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import LOG_LEVELS from '../logLevels';
import './userMessage.scss';

export const UserMessage = ({ logLevel, message, clearMessage }) => {
  const icon = (
    <i
      className={classnames([
        'message-icon',
        logLevel === LOG_LEVELS.SUCCESS ? 'icon_check-circle-filled' : '',
        logLevel === LOG_LEVELS.INFO ? 'icon_information-filled' : '',
        logLevel === LOG_LEVELS.WARNING ? 'icon_warning_filled' : '',
        logLevel === LOG_LEVELS.ERROR ? 'icon_report-filled' : '',
      ])}
    />
  );

  return (
    <div className={`user-message ${logLevel.toLowerCase()}`}>
      <span className="line-start-block">
        {icon}
        <span>{message}</span>
      </span>
      <div
        className="x-button"
        role="button"
        aria-label="Close icon"
        tabIndex={0}
        onClick={clearMessage}
        onKeyPress={(e) => {
          if (e.key === 'Enter') clearMessage();
        }}
      >
        <i className="icon_close" />
      </div>
    </div>
  );
};

UserMessage.propTypes = {
  clearMessage: PropTypes.func.isRequired,
  logLevel: PropTypes.oneOf(Object.values(LOG_LEVELS)).isRequired,
  message: PropTypes.string.isRequired,
};

export default UserMessage;
