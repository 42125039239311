import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logoutUserRequest } from 'features/userSession/redux/userSessionActionCreators';
import { getUserFullName } from 'features/userSession/redux/userSessionSelectors';
import { getConfig } from 'util/config';
import { returnToHome } from 'common/returnToHome/redux/returnToHomeActionCreators';
import UsernameButton from './usernameButton/usernameButton';
import LogoutButton from './logoutButton/logoutButton';
import HeaderDrawer from './drawer/headerDrawer';
import './header.scss';

export const Header = ({ logout, username, goToHome }) => {
  const config = getConfig();
  const [drawerOpen, setDrawerOpen] = useState();

  return (
    <>
      <header className="header">
        <button
          className="left-header-block header-block"
          type="button"
          tabIndex={0}
          onClick={goToHome}
          onKeyDown={(e) => {
            if (e.key === 'Enter') goToHome();
          }}
        >
          <img src="/images/icons-192.png" alt="HD Logo" />
          <h1>
            <span className="bolded-text">{`${window.Locale.SUPPLIER} `}</span>
            {window.Locale.ONBOARDING_TOOL}
          </h1>
        </button>
        <div className="right-header-block">
          <a
            className="header-block"
            href={`${config.REACT_APP_SUPPLIER_PORTAL_URL}/Support`}
          >
            {window.Locale.SUPPLIER_PORTAL}
          </a>
          <UsernameButton
            username={username}
            drawerOpen={drawerOpen}
            onClick={() => {
              setDrawerOpen(!drawerOpen);
            }}
          />
        </div>
      </header>
      <HeaderDrawer open={drawerOpen}>
        <LogoutButton
          logout={() => {
            setDrawerOpen(false);
            logout();
          }}
        />
      </HeaderDrawer>
    </>
  );
};

Header.propTypes = {
  logout: PropTypes.func.isRequired,
  username: PropTypes.string.isRequired,
  goToHome: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  username: getUserFullName(state),
});

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logoutUserRequest()),
  goToHome: () => dispatch(returnToHome()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
